import React from "react";
import {Link, Route, Router, Switch} from "react-router-dom";
import {createBrowserHistory} from "history";

import ShowQRCodes from "./ShowQRCodes";
import TemplatePage from "./TemplatePage";
import UploadFile from "./UploadFile";
import GenerateTemplatePage from "./GeneratePageTemplate";

const App = () => {
    const history = createBrowserHistory();

    return (
        <Router history={history}>
            <Switch>
                <Route exact path="/">
                    <ul>
                        <li>
                            <Link to="/">QR</Link>
                        </li>
                        <li>
                            <Link to="/upload">Upload & template</Link>
                        </li>
                    </ul>

                    <hr />

                    <ShowQRCodes/>
                </Route>
                <Route exact path="/upload">
                    <ul>
                        <li>
                            <Link to="/">QR</Link>
                        </li>
                        <li>
                            <Link to="/upload">Upload & template</Link>
                        </li>
                    </ul>

                    <hr />

                    <UploadFile/>
                    <br/>
                    <hr/>
                    <br/>
                    <GenerateTemplatePage/>
                </Route>
                <Route path="/template/:id">
                    <TemplatePage/>
                </Route>
            </Switch>
        </Router>
    );
};

export default App;
