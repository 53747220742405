import React, {useState, useEffect} from 'react';
import api from "../api";
import CKEditor from 'ckeditor4-react';

export const GenerateTemplatePage = () => {
    const [templateData, setTemplateData] = useState(undefined);

    useEffect(() => {
        const fetchData = async () => {
            const res = await api.get("api/template-data");

            setTemplateData(res.data.data);
        };

        fetchData();
    }, []);

    const saveTemplate = () => {
        api.post("api/upload-template", {data: templateData});
    };

    return (
        <div className="App">
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}>
                <h2>Generate template</h2>

                <div>
                    <button onClick={() => saveTemplate()}>Save</button>
                </div>
            </div>

            {(templateData !== undefined && templateData !== null) &&
            <div style={{
                margin: '0px 64px'
            }}>
                <CKEditor
                    data={templateData || ''}
                    config={{
                        allowedContent: true,
                        width: '100%',
                        height: '100vh'
                    }}
                    onChange={(event) => {
                        const data = event.editor.getData();
                        setTemplateData(data);
                        console.log({event, data});
                    }}
                />
            </div>}
        </div>
    );
};
