import React, {Fragment, useState, useEffect} from "react";
import QRCode from 'qrcode.react';
import {Constants} from "../Constants";
import api from "../api";

const FileDownload = require('js-file-download');

export const ShowQRCodes = () => {
    const [urls, setUrls] = useState([]);

    useEffect(() => {
        const getDataFromDb = async () => {
            const res = await api.get("api/urls");

            buildURLs(res.data.data);
        };

        const buildURLs = (urls = []) => {
            const build = [];

            for (const url of urls) {
                build.push(`${Constants.clientPath}/template/${url}`);
            }

            setUrls(build);
        };

        getDataFromDb();
    }, []);

    const getDataFromDb = async () => {
        const res = await api.get("api/urls");

        buildURLs(res.data.data);
    };

    const buildURLs = (urls = []) => {
        const build = [];

        for (const url of urls) {
            build.push(`${Constants.clientPath}/template/${url}`);
        }

        setUrls(build);
    };

    const removeQRCode = (id) => {
        api.post("api/delete", {id: id}).then(r => getDataFromDb());
    };

    const downloadCSV = () => {
        api.get("api/download").then(r => FileDownload(r.data, 'codes.csv'));
    };

    const deleteAll = () => {
        api.post("api/delete-all").then(r => getDataFromDb());
    };

    return (
        <React.Fragment>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}>
                <h1> QR </h1>
                <div>
                    <button onClick={() => deleteAll()}>Delete All</button>
                    <button onClick={() => downloadCSV()}>Download CSV</button>
                </div>
            </div>

            <br/>

            {
                urls.map((value, index) => (
                    <Fragment key={index}>
                        <QRCode value={value}/>
                        <h4>{value}</h4>

                        <button
                            onClick={() => removeQRCode(value.split(`${Constants.clientPath}/template/`)[1])}>Delete
                        </button>

                        <br/>
                        <br/>
                        <hr/>
                        <br/>
                        <br/>
                    </Fragment>
                ))
            }
        </React.Fragment>
    );
};
