import React, {useState} from "react";
import api from "../api";

export const UploadFile = () => {
    const [selectedFile, setSelectedFile] = useState(new Blob());

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = () => {
        const data = new FormData();
        data.append('file', selectedFile, 'SelectedFile');

        api.post('api/postData', data).then((res) => {
            console.log(res);
        });
    };

    return (
        <form>
            <h2> Upload file</h2>

            <input type="file" name="file" onChange={handleFileChange}/>

            <button onClick={handleUpload}>Upload</button>
        </form>
    );
};
