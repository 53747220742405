import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import api from "../api";
import './TemplatePage.css';

export const TemplatePage = () => {
    const [voucher, setVoucher] = useState('');
    const [mail, setMail] = useState('');
    const [templateData, setTemplateData] = useState('');
    const {id} = useParams();

    useEffect(() => {
        const getDataFromDb = async () => {
            const res = await api.get(`api/data?id=${id}`);

            setVoucher(res.data.data);
        };

        const fetchData = async () => {
            const res = await api.get("api/template-data");

            setTemplateData(res.data.data);
        };

        getDataFromDb();
        fetchData();
    }, []);

    const sendMail = () => {
        api.post('/api/sendMail', {
            id: id,
            mail: mail
        }).then((res) => {
            console.log(res);
        });
    };

    const printDocument = () => {
        window.print();
    };

    const data = templateData.replace('{voucher}', voucher);

    return (
        <React.Fragment>
            <button class='no-print' onClick={() => printDocument()}>Print</button>
            <button class='no-print' onClick={() => sendMail()}>Send Mail</button>
            <input class='no-print' type={"mail"} value={mail} onChange={(event) => setMail(event.target.value)}/>

            <div style={{border: '0px solid black', margin: '4px', padding: '4px'}}
                 dangerouslySetInnerHTML={{__html: data}}
            />
        </React.Fragment>
    );
};
